<script>
  import Input from './Input'
  import Placeholder from './Placeholder'

  export default {
    name: 'vue-treeselect--single-value',
    inject: [ 'instance' ],
    methods: {
      renderSingleValueLabel() {
        const { instance } = this
        const node = instance.selectedNodes[0]

        if (node) {
          const customValueLabelRenderer = instance.$scopedSlots['value-label']
          return customValueLabelRenderer
            ? customValueLabelRenderer({ node })
            : node.label
        } else {
          return ''
        }
      },
      renderSingleValue() {
        const { instance } = this
        const node = instance.selectedNodes[0]
        return node ? node.id : ''
      },
    },
    render() {
      const { instance, $parent: { renderValueContainer } } = this
      const shouldShowValue = instance.hasValue && !instance.trigger.searchQuery

      return renderValueContainer([
        shouldShowValue && (
          <div class="vue-treeselect__single-value" data-value={ this.renderSingleValue() }>
           { this.renderSingleValueLabel() }
          </div>
        ),
        <Placeholder />,
        <Input ref="input" />,
      ])
    },
  }
</script>
